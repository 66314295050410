import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { navigate } from 'gatsby'

function SearchBar() {
    const [searchTerm, setSearchTerm] = useState("")

    const setQuery = (e) => {
        setSearchTerm(e.target.value)
    }
    
    const handleSearch = (e) => {
        e.preventDefault()
        navigate('/search?q=' + searchTerm)  
    }

    return(
        <div className="searchBox">
            <input id="search" type="text" placeholder="Search" className="mr-sm-2" onChange={setQuery}/>
            <Button type="submit" className="btnSearch" onClick={handleSearch}>Go</Button>
        </div>
    )
}

export default SearchBar