import { Link } from 'gatsby'
import { Container, Navbar, Nav, Form} from 'react-bootstrap'
import PropTypes from "prop-types"
import React from "react"

import SearchBar from './searchbar'
import './header.css'

const Header = ({ siteTitle }) => (
  <header className="bg-light site-header">
    <Container>
      <Navbar expand="md" bg="light" variant="light" fixed="top" sticky="top" className="bc-navbar">
      <Navbar.Brand href="/" style={{color:"#555", fontSize:"2rem"}}> {siteTitle} </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarResponsive"/>
      <Navbar.Collapse id="navbarResponsive">
        <Nav as="ul" className="bc-nav-left">
          <Nav.Item as="li">
            <Link to="/people" className="nav-link" activeClassName="active">People</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/top-books" className="nav-link" activeClassName="active">Top-Books</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/explore" className="nav-link" activeClassName="active">Explore</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/book-talk" className="nav-link" activeClassName="active">Book-Talk</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/blog" className="nav-link" activeClassName="active">Blog</Link>
          </Nav.Item>
        </Nav>
        <Nav as="ul">
          <Nav.Item as="li">
            <div>
            <Form inline className="mr-sm-2"> 
                <SearchBar />
            </Form>
            </div>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
      </Navbar>
    </Container>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
