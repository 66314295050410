/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import {FaTwitter} from 'react-icons/fa'

import Header from "./header"
import './layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <Container fluid className="main" style={{padding:"0px", height:"100vh"}}>
          <Row noGutters className="justify-content-center">
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} />
            </Col>
          </Row>  
        <Row noGutters>
            <Col>
              <Container className="mt-2 content">
                <main>{children}</main>
              </Container>
            </Col>
        </Row> 
        <Row></Row>
    {/*</Container>
    <Container fluid className="px-2 content">*/}
      <Row noGutters className="site-footer">
        <Col className="footer-col">   
          <footer>
            Copyright © {new Date().getFullYear()} BooksChatter
            {` `}
            <a href="https://twitter.com/BooksChatterBot"><FaTwitter/></a>
          </footer>
        </Col>
      </Row>
    </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
